body{
  overflow-x: hidden;
}
table tbody tr:nth-child(even){
  background: #fafafa;
}
table tbody tr:hover{
  background-color: #ebebeb;
}
.leades-data{
  padding: 30px 15px;
  position: relative;
}
.title-heading{
  font-size: 30px;
  line-height: 40px;
  color: #000;
  font-weight: 600;
  margin-bottom: 10px;
  margin-top: 0;
}
.sort_dropdown{
  display: flex;
  justify-content: flex-end;
  margin-bottom: 30px;
  align-items: center;
}
.sort_dropdown .Dropdown-root{
  width: 152px;
}
.sort_dropdown p{
  margin-right: 10px;
}
.leades-data table{
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
}
.leades-data table thead{
  background: #000;
}
.leades-data table tbody{
  background: #ffffff;
}
.leades-data table th{
  padding: 15px;
  text-align: left;
  color: #ffffff;
}
.leades-data table td{
  padding: 15px;
  text-align: left;
  color: #000;
}
.leades-data table .Dropdown-root{
  width: 152px;
}
.Dropdown-root.is-open .Dropdown-menu{
  max-height: 200px;
}
.Dropdown-root .Dropdown-arrow{
  top: 18px;
}

.pagination{
  position: relative;
  margin-top: 40px;
  text-align: center;
}
.pagination ul{
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pagination ul li a{
  display: block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  background-color: #ccc;
  color: #000;
  text-decoration: none;
  margin: 0 5px;
}
.pagination ul li.active a, 
.pagination ul li a:hover,
.pagination ul li a:focus{
  background: #000;
  color: #ffffff;
}

/*------------media-css----------*/
@media screen and (max-width: 576px){
  .sort_dropdown{
    justify-content: flex-start !important;
  }
}
@media screen and (max-width: 1199px){
.responsive-table{
   overflow-x: auto;
}
}