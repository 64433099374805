.mainWrap {
    display: flex;
    justify-content: space-between;
    background-image: url(../assets//work.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: right;
    height: calc(100vh - 64px);
}
.left-side-content {
    width: 100%;
}
.workImage{
    display: flex;
    position: absolute;
}
.clock {
    text-align: right;
    margin-top: 10px;
    margin-right: 10px;
}
.cardRowOne{
    padding-top: 40px;
    padding-left: 50px;
    display: flex;
}