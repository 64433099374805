.tickets-data{
    padding: 30px 15px;
    position: relative;
}
.tickets_filters{
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    align-items: center;
}
.search-form form{
    display: flex;
}
.search-form input, .search-form .searchButton {
    position: relative;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 2px;
    box-sizing: border-box;
    color: #333;
    cursor: default;
    outline: none;
    padding: 0 15px;
    transition: all 200ms ease;
    height: 42px;
    line-height: 42px;
    width: 100%;
}
.search-form input{
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none;
}
.search-form .searchButton{
    background-color: #000;
    color: #ffffff;
    border-left: none;
    border-color: #000;
    max-width: 46px;
}
.title-heading{
    font-size: 30px;
    line-height: 40px;
    color: #000;
    font-weight: 600;
    margin-bottom: 10px;
    margin-top: 0;
}
.sort_dropdown{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 30px;
}
.tickets-data .sort_dropdown{
    margin-bottom: 0;
}
.sort_dropdown .Dropdown-root{
      width: 152px;
}
.sort_dropdown p{
    margin-right: 10px;
}
.tickets-data table{
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
}
  .tickets-data table thead{
    background: #000;
  }
  .tickets-data table tbody{
    background: #ffffff;
  }
  .tickets-data table th{
    padding: 15px;
    text-align: left;
    color: #ffffff;
  }
  .tickets-data table td{
    padding: 15px;
    text-align: left;
    color: #000;
  }
  .tickets-data table .Dropdown-root{
    width: 152px;
}
  .Dropdown-root.is-open .Dropdown-menu{
    max-height: 200px;
  }
  .Dropdown-root .Dropdown-arrow{
    top: 18px;
  }
  .pagination{
    position: relative;
    margin-top: 40px;
    text-align: center;
  }
  .pagination ul{
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .pagination ul li a{
    display: block;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    background-color: #ccc;
    color: #000;
    text-decoration: none;
    margin: 0 5px;
  }
  .pagination ul li.active a, 
  .pagination ul li a:hover,
  .pagination ul li a:focus{
    background: #000;
    color: #ffffff;
  }

  /*------------media-css----------*/
@media screen and (max-width: 576px){
    .sort_dropdown{
      justify-content: flex-start !important;
    }
    .tickets_filters{
        display: block;
    }
    .tickets-data .sort_dropdown{
        margin-top: 20px;
    }
  }
  @media screen and (max-width: 1199px){
  .responsive-table{
     overflow-x: auto;
  }
  }