a, button, .delete_btn{
    cursor: pointer;
}
.agents-data{
    padding: 30px 15px;
    position: relative;
}
.agents_filters{
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    align-items: center;
    margin-top: 30px;
}
.agents_filters h4{
    margin: 0;
    font-size: 24px;
    line-height: 34px;
    color: #000;
}
.agent-search-form input, .agent-search-form .searchButton {
    position: relative;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 2px;
    box-sizing: border-box;
    color: #333;
    cursor: default;
    outline: none;
    padding: 0 15px;
    transition: all 200ms ease;
    height: 42px;
    line-height: 42px;
    width: 100%;
}
.agent-search-form form{
    display: flex;
}
.agent-search-form input{
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none;
}
.agent-search-form .searchButton{
    background-color: #000;
    color: #ffffff;
    border-left: none;
    border-color: #000;
    max-width: 46px;
    min-width: 46px;
}
.title-heading{
    font-size: 30px;
    line-height: 40px;
    color: #000;
    font-weight: 600;
    margin-bottom: 10px;
    margin-top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.title-heading span{
    font-size: 20px;
    line-height: 30px;
}
.sort_dropdown{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 30px;
}
.agents-data .sort_dropdown{
    margin-bottom: 0;
}
.sort_dropdown .Dropdown-root{
      width: 152px;
}
.sort_dropdown p{
    margin-right: 10px;
}
.agents-data table{
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
}
  .agents-data table thead{
    background: #000;
  }
  .agents-data table tbody{
    background: #ffffff;
  }
  .agents-data table th{
    padding: 15px;
    text-align: left;
    color: #ffffff;
  }
  .agents-data table td{
    padding: 15px;
    text-align: left;
    color: #000;
  }
  .agents-data table .Dropdown-root{
    width: 152px;
}
  .Dropdown-root.is-open .Dropdown-menu{
    max-height: 200px;
  }
  .Dropdown-root .Dropdown-arrow{
    top: 18px;
  }
  .ReactModal__Overlay{
      z-index: 9999;
  }
  .add_agent{
      padding: 30px;
      background-color: #ffffff;
      max-width: 450px;
      margin: 30px auto;
      text-align: center;
      width: 100%;
  }
  .add_agent h2{
      margin-top: 0;
  }
  .add_agent input,  .add_agent .submit_button{
    position: relative;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 2px;
    box-sizing: border-box;
    color: #333;
    cursor: default;
    outline: none;
    padding: 0 15px;
    transition: all 200ms ease;
    height: 42px;
    line-height: 42px;
    margin-bottom: 15px;
    width: 100%;
  }
  .add_agent .submit_button{
      background-color: #000;
      text-align: center;
      color: #ffffff;
  }
  .pagination{
    position: relative;
    margin-top: 40px;
    text-align: center;
  }
  .pagination ul{
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .pagination ul li a{
    display: block;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    background-color: #ccc;
    color: #000;
    text-decoration: none;
    margin: 0 5px;
  }
  .pagination ul li.active a, 
  .pagination ul li a:hover,
  .pagination ul li a:focus{
    background: #000;
    color: #ffffff;
  }

  .ReactModal__Overlay{
      background-color: rgba(0, 0, 0, .4) !important;
  }
  .ReactModal__Content{
    max-width: 400px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    max-height: 300px;
    height: 100%;
  }
  .addAgentModal{
    display: block;
    margin-top: 150px;
  }

  .ReactModal__Content .modal-content{
      display: block;
      text-align: center;
  }
  .closemodal{
      position: absolute;
      right: 20px;
      top: 20px;
      background-color: #000;
      color: #ffffff;
      height: 30px;
      width: 30px;
      line-height: 30px;
      border: none;
      outline: none;
      border-radius: 0;
  }
  .modal-content .btns button{
    background-color: #000;
    color: #ffffff;
    height: 40px;
    line-height: 40px;
    border: none;
    outline: none;
    border-radius: 0;
    margin: 0 5px;
    min-width: 80px;
  }
  /* .submit_value{

  } */
  .cancel_value{
     background-color: #1976d2 !important;
  }
  .agents-data button{
    background-color: #000;
    color: #ffffff;
    height: 40px;
    line-height: 40px;
    border: none;
    outline: none;
    border-radius: 0;
    margin: 0 5px;
    min-width: 150px;
  }
  .add_agent_btn:hover,
  .add_agent_btn:focus{
    background-color: #1976d2;
  }

    /*------------media-css----------*/
@media screen and (max-width: 576px){
    .sort_dropdown{
      justify-content: flex-start !important;
    }
    .tickets_filters{
        display: block;
    }
    .title-heading{
        display: block;
    }
    .title-heading span{
        display: block;
    }
    .add_agent{
        padding: 15px;
    }
    .agents_filters{
        display: block;
    }
    .agent-search-form{
        margin-top: 20px;
    }
  }
  @media screen and (max-width: 1199px){
  .responsive-table{
     overflow-x: auto;
  }
  }